import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppEUShenanigans = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our Future! Vote in the EU elections - here's how 🗳"
      description="Click the link and send a message to friends on WhatsApp - give them a voice! ➡️"
      shareImage="english-ireland"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey! Are you Irish and living in the UK?
We have the right to vote in the EU elections here - this is our home too!

Send this message to your Irish and European friends in the UK -
Click here: https://saveourfuture.uk/eushenanigans

*Register to vote by May 7th*
Follow the simple steps here (takes 2 mins):
https://saveourfuture.uk/simple

Do it now! Top tip: apply for a postal vote too.

*IMPORTANT!*
💥 Send this message to your Irish friends in the UK! 💥
Click here: https://saveourfuture.uk/eushenanigans

🗣 *THIS TIME, OUR VOICE COUNTS* 🗣"`}
    />
    <Interstitial />
    <Disclaimer />
  </Layout>
);

WhatsAppEUShenanigans.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppEUShenanigans;

export const query = graphql`
  query EUShenanigansQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
